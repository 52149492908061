import React from 'react'
// import data
import { data } from '../data/data'
// syles
import { Cards, ConCards, ConImg, ConNosotros, ContPagesNosotros, Paragraph } from '../styles/Nosotros'
// Helmet
import { Helmet } from 'react-helmet'
import PoliticaSalud from '../components/PoliticaSalud'




const Nosotros = () => {

    //Get data
    const [datos] = data;
    const { empresa: { dropDownMenu: { nosotros: { helmet, cards } } } } = datos

    // extract values of cards
    const card = Object.values(cards);

    return (
        <>
            <Helmet><title>{helmet} – FACCEL S.A.S.</title></Helmet>
            <h1>{helmet}</h1>


            <ContPagesNosotros>
                <div className='ContSectionNo'>
                    <ConNosotros>

                        <ConCards>
                            {
                                card.map((item) =>
                                    <Cards key={item.id}>
                                        <Paragraph>
                                            <div>
                                                <p className='title_card'>{item.title}</p>
                                                <p className='p_card'>{item.paragraph.split('\n').map((line, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            {line}
                                                            <br />
                                                        </React.Fragment>
                                                    )
                                                })}</p>
                                            </div>
                                        </Paragraph>
                                        <ConImg>
                                            <img src={require(`../images/${item.img}`)} alt={item.id} />
                                        </ConImg>
                                    </Cards >
                                )
                            }
                        </ConCards>

                        <PoliticaSalud />

                    </ConNosotros>
                </div>
            </ContPagesNosotros>
        </>
    )
}

export default Nosotros;