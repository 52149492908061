import React from 'react'
import { data } from '../data/data';
import { Helmet } from 'react-helmet';
import { ConProducto } from '../styles/Productos';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { BtnWhatsapp, CardCert, ContCards, ContCertfContact, ContCertificaciones, ContImgCertif, ContSliderCert, ImgCertif, SubContSlider, SubTitle, Title } from '../styles/Certificaciones';
import { ContContact, ContContactForm, ContImgComtact } from '../styles/Contact';

import ImgBannerPc from '../images/certificado.png';
import CertificadoForm from '../components/CertificadoForm';



const Certificaciones = () => {
  //Get data
  const [datos] = data;
  const { certificaciones: { paragraph, paragraph1, helmet, title, subTitle, titleForm, cardsC_2,
    metaHelmet: { meta0, meta1, link } },
    inicio: { whatsappUrl },
    productos: { documentOnac: { cards } }
  } = datos

  //get elements of object
  const dataMeta0 = Object.values(meta0);
  const dataMeta1 = Object.values(meta1);

  // extract values of cards
  const card = Object.values(cards);
  const card1 = Object.values(cardsC_2)

  // silder......
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>{helmet} – FACCEL S.A.S.</title>
        {dataMeta0.map((item) => <meta name={item.name} content={item.content} />)}
        {dataMeta1.map((item) => <meta property={item.name} content={item.content} />)}
        <link rel={link.rel} href={link.href} />
      </Helmet>
      <h1>{helmet}</h1>


      <ContCertificaciones>
        <div>
          <ConProducto>

            <Title><p>{title}</p></Title>
            <SubTitle>{subTitle}</SubTitle>

            <ContCards>
              <ContSliderCert>
                <SubContSlider>
                  <Slider {...settings}>

                    {
                      card.map((item) =>
                        <CardCert key={item.id}>

                          <span>
                            <p>{item.textP.split('\n').map((line, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              )
                            })}</p>
                          </span>

                          <span>
                            <img src={require(`../images/${item.img}`)} alt={item.name} />
                          </span>

                        </CardCert >
                      )
                    }

                  </Slider>
                  <p className='AuxParagraph'>{paragraph1}</p>
                </SubContSlider>
              </ContSliderCert>

            </ContCards>

            <SubTitle>{paragraph}</SubTitle>
            <BtnWhatsapp>
              <div
                to={whatsappUrl}
                target='_blank'
              >
                SOLICITAR CERTIFICADO VÍA WHATSAPP
              </div>
            </BtnWhatsapp>

            <ContImgCertif>
              {
                card1.map((item, index) =>
                  <ImgCertif key={index}>
                    <img src={require(`../images/${item.img}`)} alt="" />
                  </ImgCertif>
                )
              }
            </ContImgCertif>

            <ContCertfContact>
              <ContImgComtact>
                <img src={ImgBannerPc} alt="imagen" />
              </ContImgComtact>

              <ContContactForm >
                <div className='contContact2'>
                  <p className='title'>{titleForm}</p>
                  <CertificadoForm />
                </div>
              </ContContactForm>
            </ContCertfContact>

          </ConProducto>
        </div>
      </ContCertificaciones>
    </>
  )
}

export default Certificaciones