import styled from "styled-components";

export const ContContact = styled.div `
  /* border:2px solid blue;//.................. */
  width: 90%;
  height: max-content;
  display: flex;
  justify-content: space-around;
  margin: 4em auto 4em;
  @media (max-width:1270px) {
    margin-top: 90px;
    margin-bottom: 50px;
  }
  @media (min-width:1730px) {
    height: 870px;
  }
`
export const ContImgComtact = styled.div`
  /* border: 1px solid red;//.......................... */
  width: 40%;
  overflow: hidden;
  display: flex;
  padding: 1.5em;
  @media (max-width:1199px) {
      display: none;
  }
  @media (max-width:1340px) {
    width: 50%;
  }
    img{
      /* border: 1px solid red;//....................... */
      width: 100%; 
      object-fit: cover;
      object-position: center 5px;
      }
`
export const ContContactForm = styled.div`
    /* border: 1px solid red;//....................... */
    width: 50%;
    height: auto;
    @media (max-width:1190px) {
      width: 700px;
    }
    @media (max-width:780px) {
      width: 100%;
    }
    .contContact2 {
      /* border: 2px solid red; //.................. */
      margin-top: 2.1em;
      height: 90%;
      .title {
        font-size: clamp(28px, 3.3vw, 60px);
        font-weight: 900;
        padding-left: 0.2em;
        @media (max-width: 600px) {
          font-size: 30px;
        }
      }
      .subTitle {
        font-size: clamp(14px, 1.4vw, 28px);
        font-weight: 600;
        margin: 1em 0 0 0;
        padding-left: 0.2em;
        @media (max-width: 600px) {
          margin: 2rem 0;
        }
      }
      div {
        padding-top: .5em;
        a {
          text-decoration: none;
          padding: 1.5rem 6.5rem;
          background-color: var(--color4);
          font-size: 2rem;
          font-weight: 600;
          color: var(--color5);
          border-radius: 0.7em;
          @media (max-width: 766px) {
            padding: 1.5rem 3vw;
            font-size: 3.4vw;
            font-weight: 100;
          }
          &:hover {
            color: var(--color1);
          }
          &:active {
            background-color: var(--color7);
          }
        }
      }
    }
`
export const FormContact = styled.div `
  .cont_form_mail {
    form {
      flex-direction: column;
      display: flex;
      .aux_text{
        width: 96%;
        margin: 0 auto 1em;
      }
      .firstBlock {
        display: flex;
        @media (max-width:700px) {
          flex-direction: column;
        }
        div {
          width: 50%;
          display: flex;
          flex-direction: column;
          padding: 0 1rem;
          @media (max-width:700px) {
            width: 94%;
          }
        }
      }
      .form_label {
        font-size: clamp(16px, 1.5vw, 26px);
        padding: 0 0 0.5em 0;
        font-weight: 600;
        @media (max-width:700px) {
          width: max-content;
        }
        span {
          color: red;
          font-size: 20px;
        }
      }
      .form_input,
      select{
        margin-bottom: 1em;
        height: 2.8em;
        border-radius: 1em;
        border: 2px solid var(--color2);
        padding: 0 0 0 0.5em;
        background-color: white!important;
        @media (max-width:700px) {
          width: 95%;
        }
        
        option:disabled {
          color: transparent;

        }
        &::placeholder {
          color: var(--color4);
        }
        &:focus {
          border: 2px solid var(--faccel1);
          outline: none;
          &::placeholder {
            color: var(--color3);
          }
        }
      }
      .class_1 {
        margin-bottom: 1em;
        height: 2.8em;
        border-radius: 0.5em;
        border: 2px solid red;
        padding: 0 0 0 0.5em;
        &::placeholder {
          color: rgba(255, 0, 0, 0.623);
        }
        &:focus {
          border: 2px solid var(--color1);
          outline: none;
          &::placeholder {
            color: white;
          }
        }
      }
      input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Estilo para el control personalizado */
      input[type="number"] {
        border: 2px solid var(--color2);
        background: white;
        font-size: 12px;
        width: 100%;
        @media (max-width:700px) {
          width: 94%;
        }
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        display: none;
      }
    }
    .secondForm {
      /* border: 1px solid red;//...................... */
      display: flex;
      flex-direction: column;
      width: 96%;
      margin: auto;
      @media (max-width:700px) {
        select{
          width: 100%;
        }
      }
    }
  }
`
export const TextArea = styled.textarea `
  margin-bottom: 1em;
  border-radius: 1em;
  border: 2px solid var(--color2);
  padding: 1em;
  option:disabled {
    color: transparent;
  }
  &::placeholder {
    color: var(--color4);
  }
  &:focus {
    border: 2px solid var(--faccel1);
    outline: none;
    &::placeholder {
      color: var(--color3);
    }
  }
`
export const CheckboxLabel = styled.label `
  /* border: 1px solid red;//................... */
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
  span {
    width: 90%;
    font-size: 12px;
  }
`
export const CheckboxInput = styled.input `
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color4);
  position: relative;
  transition: all 0.3s ease;

  &:checked {
    background-color: var(--color4);
  }

  &:checked::after {
    content: "\\2714";
    font-size: 14px;
    color: var(--color5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    cursor: pointer;
  }
`
export const BtnFormContact = styled.div`
  /* border: 1px solid red;//...................... */
  width: 100%;
  text-align: center;
  padding: .5em 0;
  margin-top: 3em;

  button {
    background-color: var(--faccel1);
    border: 4px solid transparent;
    color: var(--color5);
    padding: .2em 1em;
    width: 30%;
    cursor: pointer;
    font-size: 25px;
    font-weight: 900;
    opacity: 0.85;    
    @media (max-width:700px) {
        width: 80%;
    }
    &:hover{
      opacity: 1;
    }
    &:active{
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88); 
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88);
    }
  }
`
export const ContCheck = styled.div`
    /* border: 1px solid red;//.............................. */
    display: flex;
    flex-direction: column;
    margin: 1em 0;
    label{
        display: flex;
        align-items: center;
        margin:.5em 0;
        p{
            margin-left: 1em;
            font-weight:500;
        }
    }
`
export const CheckboxInputLibro = styled.input`
    /* border: 1px solid red;//....................... */
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid var(--color4);
    /* border-radius: 50%; */
    position: relative;
    transition: all 0.3s ease;
    &:checked {
        background-color: var(--color4);
    }

    &:checked::after {
    content: "\\2714";
    font-size: 14px;
    color: var(--color5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    }
    &:hover {
        cursor: pointer;
    }
`

