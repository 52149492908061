import React, { useEffect, useState } from "react";
//import react router dom
import { Link } from "react-router-dom";
//import data
import { data } from "../data/data";
//styles
import {
    Address,
    BtnBase,
    DropdownButton,
    DropdownContainer,
    DropdownContent,
    LogoNav,
    MenuPcLaptop,
    StyledNav
} from "../styles/NavBar";
// icons
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
// images
import Logo from '../images/logo_faccel.svg';
import MenuMobile from "../components/MenuMobile";





const NavBar = () => {

    // get data
    const [datos] = data;
    // destructuring data
    const { inicio, servicios, certificaciones, contacto,
        empresa: { dropDownMenu: { nosotros: { name, url } } },
        productos: {
            celdas: { nameC, cardsCeldas },
            encerramientos: { nameE, cardsEncerramientos }
        } } = datos;
    const serviciosSub = Object.values(servicios.dropDownMenu);
    const itemsCeldas = Object.values(cardsCeldas);
    const itemsEncerramientos = Object.values(cardsEncerramientos);

    //...................Diffused..................
    const [isDiffused, setIsDiffused] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (window.pageYOffset > 0) {
                setIsDiffused(true);
            } else {
                setIsDiffused(false);
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    //.............................................

    return (
        <>
            <StyledNav>

                <Address className={`navbar ${isDiffused ? 'is-diffused' : ''}`}>
                    <div>
                        <Link className="redes_barra_nav" target="_blank" to={inicio.instagramUrl}><InstagramIcon /></Link>
                        <Link className="redes_barra_nav" target="_blank" to={inicio.facebookUrl}><FacebookIcon /></Link>
                        <span><PhoneAndroidOutlinedIcon /><p>{inicio.phone}</p></span>
                        <Link className="mailTo" to={`mailto:${inicio.email}`}><EmailOutlinedIcon /><p>{inicio.email}</p></Link>
                    </div>
                </Address>

                <div className="ContMenu">

                    <LogoNav to={inicio.url}>
                        <img src={Logo} alt={inicio.id} />
                    </LogoNav>

                    <MenuPcLaptop>

                        <BtnBase id="inicio" className="inicio">
                            <Link to='/'>Inicio</Link>
                        </BtnBase>

                        <BtnBase id="inicio" className="inicio">
                            <Link to={url}>{name}</Link>
                        </BtnBase>

                        <DropdownContainer key='servicios'>
                            <DropdownButton>{servicios.nameS}<ArrowDropDownSharpIcon /></DropdownButton>
                            <DropdownContent className='dropdown-content'>
                                {
                                    serviciosSub.map((item) =>
                                        <div>
                                            <Link id={item.name} key={item.id} to={item.url}>
                                                {item.name}
                                            </Link><br />
                                        </div>
                                    )
                                }
                            </DropdownContent>
                        </DropdownContainer>

                        <DropdownContainer key={nameC}>
                            <DropdownButton>{nameC}<ArrowDropDownSharpIcon /></DropdownButton>
                            <DropdownContent className='dropdown-content'>
                                {
                                    itemsCeldas.map((item) =>
                                        <div>
                                            <Link id={item.name} key={item.id} name="certificaciones" to={item.url}>
                                                {item.name}
                                            </Link><br />
                                        </div>
                                    )
                                }
                            </DropdownContent>
                        </DropdownContainer>

                        <DropdownContainer key={nameE}>
                            <DropdownButton>{nameE}<ArrowDropDownSharpIcon /></DropdownButton>
                            <DropdownContent className='dropdown-content'>
                                {
                                    itemsEncerramientos.map((item) =>
                                        <div>
                                            <Link id={item.name} key={item.id} name="certificaciones" to={item.url}>
                                                {item.name}
                                            </Link><br />
                                        </div>
                                    )
                                }
                            </DropdownContent>
                        </DropdownContainer>

                        <BtnBase id="inicio" className="inicio" >
                            <Link to={certificaciones.url}>{certificaciones.name}</Link>
                        </BtnBase>

                        <BtnBase id="inicio" className="inicio">
                            <Link to={contacto.url}>{contacto.name}</Link>
                        </BtnBase>

                    </MenuPcLaptop>

                    <MenuMobile />
                </div>
                <div>
                </div>
            </StyledNav>
        </>
    );
};

export default NavBar;


