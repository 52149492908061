import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home";
import "./index.css";
import NavBar from "./pages/Navbar";
import Footer from "./components/Footer";
import FloatingBtn from "./components/FloatingBtn";
import ScrollToTop from "./components/ScrollToTop";
import ButtonToTop from "./components/ButtonToTop";
import Nosotros from "./pages/Nosotros";
import Certificaciones from "./pages/Certificaciones";
import Contacto from "./pages/Contacto";
import Servicios from "./pages/Servicios";
import Celdas from "./pages/Celdas";
import Encerramientos from "./pages/Encerramientos";


function App() {

  //Button scroll to top........................
  const [showButton, setShowButton] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      if (position > window.innerHeight / 2) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  //Button scroll to top........................

  return (
    <>
      <Router>

        <NavBar />
        <FloatingBtn />
        <ScrollToTop />
        {showButton && <ButtonToTop />}

        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="https//api.whatsapp.com/send?phone=573186396096"element={<FloatingBtn />}/>

          <Route path="/acerca-de-nosotros" element={<Nosotros />} />
          <Route exact path="/servicios/:id" element={<Servicios />} />
          <Route exact path="/celdas/:id" element={<Celdas />} />
          <Route exact path="/encerramientos/:id" element={<Encerramientos />} />
          <Route path="/certificaciones" element={<Certificaciones />} />
          <Route path="/acerca-de-nosotros" element={<Nosotros />} />
          <Route path="/contacto" element={<Contacto />} />
          
        </Routes>
        <Footer />
      </Router>
    </>
  )
}


export default App;

