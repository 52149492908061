import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContPagesNosotros = styled.div`
    /* border: 1px solid blue;//......................... */
    margin-top:clamp(110px, 9vw, 150px);
    background-color: var(--color5);
    padding-top: 4em;
    @media (max-width:1270px) {
        padding-top: 0;
    }
    .ContSectionNo{
        /* border: 1px solid blue;//......................... */
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: auto;
        @media (min-width:1270px) {
            justify-content: space-around;
        }
        @media (max-width:1270px) {
            width: 100%;
        }
    }
`
export const ConNosotros = styled.div`
    @media (max-width:1270px) {
    width: 72%;
    /* border: 2px solid green;//.................... */
    margin-bottom: 4.1rem;
        width: 100%;
        padding: 0;
        margin-bottom: 0;
    }
`
export const ContNumber1 = styled.div`
    /* border: 2px solid green;//.................... */
    background-color: var(--color2);
    height: max-content;
    padding: 5%;
    @media (max-width:800px) {
        padding: 1em;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
    div{
    /* border: 2px solid green;//.................... */
    width: clamp(225px, 17vw, 384px);
    height: clamp(225px, 17vw, 384px);
    overflow: hidden;
    margin: 0 1.8em 0 1.4em;
    float: right;
    @media (max-width:800px) {
        float: none;
    }
    img{
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 50%;
        
        }

    }
    .title_section{
        font-size: 3.7vw;
        font-weight: 900;
        line-height: 1.1em;
        margin-bottom: .5em;
        @media (max-width:1270px) {
            display: none;
        }
    }
    .paragraph_section{
        width:100%;
        font-size: clamp(14px, 1.3vw, 25px);
        margin-bottom: .8em;
        @media (max-width:800px) {
                margin-top: 16px;
            }
    }
`
export const TitleSection = styled.div`
    border: 2px solid green;//....................
    text-align:center;
    background-color: var(--color2);
    p{
        font-size: clamp(25px,3vw, 57px);
        font-weight: 900;
    }
`
export const ConCards = styled.div`
    /* border: 1px solid red;//.................. */
    /* background-color: var(--color2); */
    width: 90%;
    padding: 1.5em 0 0 0;
    margin: auto;
    /* flex-direction: column; */
    align-items: center;
    @media (max-width:568px) {
        display: flex;
        flex-direction:column;
    }
    @media (max-width:1270px) {
        width: 90%;
        margin: auto;
    }
`
export const Cards = styled.div`
    /* border: 1px solid blue;//.................. */
    width: 100%;
    height: auto;
    min-height: 250px;
    display: flex;
    background-color: var(--color2);
    margin-bottom: 2rem;
    @media (max-width:700px) {
        flex-direction: column;
        height: auto;
        width: 90%;
        margin: auto;
    }
    &:nth-child(even){
        flex-direction: row-reverse;
        @media (max-width:700px) {
            flex-direction: column;
        }
    }
    &:nth-last-child(1){
        flex-direction: row;
        background-color: var(--faccel1);
        color: var(--color5);
        @media (max-width:700px) {
            flex-direction: column;
        }
        .title_card{
            /* border: 1px solid red;//................... */
            font-size: clamp(25px, 3vw, 56px);
            text-align: start;
            color: var(--color5);
        }
    }
`
export const ConImg = styled.div`
    /* border: 1px solid red;//................. */
    width: 50%;
    overflow: hidden;
    @media (max-width:700px) {
        height:250px;
        width: 100%;
    }
    img{
        /* border: 1px solid red;//................. */
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position:center center;
        @media (max-width:700px) {
            object-position:center 0; 
        }
    }
`
export const Paragraph = styled.div`
    /* border: 1px solid blue;//................. */
    width: 50%;
    padding: 2.5vw 4vw;
    height: auto;
    display: flex;
    align-items: center;
    @media (max-width:700px) {
        padding: 1em;
        width: 100%;
        height:50%;
    }
    
    div{
        /* border: 1px solid blue;//................. */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: justify;
        .title_card{
            font-size: clamp(25px, 3.5vw, 66px);
            font-weight: 800;
            margin-bottom: 1rem;
            color: var(--faccel1);
    }
    .p_card{
        /* border: 1px solid blue;//................. */
        font-size: clamp(12px, 1.3vw, 25px);
        display: flex;
    }
    }
    
`
export const MenuMobileNos = styled.div`
        /* border: 1px solid red;//....................... */
        display: none;
        background-color: var(--color4);
        a{
            text-decoration: none;
            color: var(--color1);
            div{
                /* border: 1px solid red;//....................... */
                height: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-weight: 800;
            }
        }
        @media (max-width:1270px) {
            display: block;
        }
`
export const MobileTitle = styled.div`
    background-color: var(--color1);
    height: 52px;
    color: var(--color4);
    display: none;
    p{
        font-weight: 800;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media (max-width:1270px) {
            display: block;
        }
`
export const BtnLink = styled(Link)`
    /* border: 1px solid red;//........... */
    text-decoration: none;
    div{
        margin-top: 1em;
        border: 2px solid var(--faccel1);//...........
        width: 10em;
        text-align: center;
        padding: 1em;
        background-color: var(--faccel1);
        color: var(--color5);
        font-weight: 700;
        &:active{
            border: 2px solid var(--color2);//...........
        }
    }
`







