import React from 'react'
//styles
import { CardMail, ContCardsM, TitleM } from '../styles/SecMailPhone';
//import material ui
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
//const e-mails
const facturacion = 'administrativo@faccel.com';
const Propuestas = 'comercial@faccel.com';
const Calidad = 'calidad@faccel.com';

const SecMailPhone = () => {
    return (
        <>
            <ContCardsM>
                <CardMail to={`mailto:${facturacion}`} >
                    <ForwardToInboxIcon />
                    <div>
                        <TitleM>Facturación y cartera</TitleM>
                        <p>administrativo@faccel.com</p>
                        <p>316 6165629</p>
                    </div>
                </CardMail>
                <CardMail to={`mailto:${Propuestas}`} >
                    <ForwardToInboxIcon />
                    <div>
                        <TitleM>Propuestas Comerciales</TitleM>
                        <p>comercial@faccel.com</p>
                        <p>322 7816366</p>
                    </div>
                </CardMail>
                <CardMail to={`mailto:${Calidad}`} >
                    <ForwardToInboxIcon />
                    <div>
                        <TitleM>Calidad y SST</TitleM>
                        <p>calidad@faccel.com</p>
                        <p>321 8532096</p>
                    </div>
                </CardMail>

            </ContCardsM>
        </>
    )
}

export default SecMailPhone