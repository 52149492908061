import { Link } from "react-router-dom"
import styled from "styled-components"



export const ContCardsM = styled.div`
    /* border: 1px solid red;//............... */
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 2em auto ;
    padding-top:9.5em;
    @media (max-width:920px) {
        flex-direction: column;
    }
`
export const CardMail = styled(Link)`
    border: 4px solid var(--color3);//...............
    width: 19em;
    height: auto;
    display: flex;
    align-items: center;
    padding: .5em 1em;
    text-decoration: none;
    color: var(--faccel3);
    border-radius:1em;
    @media (max-width:1160px) {
        flex-direction: column;
        width: 14em;
        text-align: center;
    }
    @media (max-width:920px) {
        flex-direction: row;
        width: 19em;
        margin: 1em auto;
        text-align:left;

    }
    @media (max-width:380px) {
        width: 90%;
    }
    div{
        width: auto;
    }
    &:hover{
        transition: linear 350ms;
        border: 4px solid var(--faccel1);//...............
    }
    &:active{
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88); 
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88);
    }
    svg{
        font-size: 5em;
        color: var(--faccel1);
        padding-right:.2em;
        @media (max-width:380px) {
            font-size: 3em;
        }
    }
    
`
export const TitleM = styled.p`
    font-size: 1.3em;
    font-weight: 600;
    @media (max-width:920px) {
        font-size: 1em;
    }
`