import React from 'react'
//import react router dom
import { Link } from 'react-router-dom';
//data
import { data } from '../data/data';
// import style slider
import Slider from 'react-slick';
//styles
import { CardProductos, ContImg, ContSecProductos, ContSliderProd, TitleSectionP } from '../styles/SecProductos';



const SecProductos = () => {

    // get data
    const [datos] = data;
    //destructuring data
    const { productos: {name, documentOnac: { cards } } } = datos;
    const card = Object.values(cards);

    // silder......
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1160,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <ContSecProductos>
                <TitleSectionP>{name}</TitleSectionP>
                <ContSliderProd>
                    <Slider {...settings}>

                        {
                            card.map((item) =>
                                <CardProductos key={item.id}>

                                    <ContImg>
                                        <img src={require(`../images/${item.img}`)} alt={item.name} />
                                    </ContImg>

                                    <span>
                                        <p>{item.textP.split('\n').map((line, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            )
                                        })}</p>
                                    </span>
                                    <Link to={item.url} >VER MÁS</Link>

                                </CardProductos>
                            )
                        }

                    </Slider>
                </ContSliderProd>


            </ContSecProductos>
        </>
    )
}

export default SecProductos;