import styled from "styled-components";

export const ButtonTop = styled.button`
    /* border: 1px solid red;//.................. */
    position: fixed;
    bottom: 20px;
    right: 10px;
    border: none;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 999;
    @media (max-width:600px) {
        width: 40px;
        height: 40px;
    }
    svg{
        font-size: 36px;
        font-weight: 900;
        color: var(--faccel1);
    }
    &:hover{
        transition: 100ms;
        transform: translateY(-4px);
    }
    &:active{
        transition: 100ms;
        transform: translateY(1px);
    }
`















