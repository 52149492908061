import React from 'react'
//import images
import image from '../images/mas-info.png'
//styles
import { Btn1, Btn2, ContBanner, ContImg, ContParagraph } from '../styles/BannerContactanos'
//import data
import { data } from '../data/data'

const BannerContactanos = () => {

    //get data
    const [datos] = data;
    //destructuring data
    const {contacto:{url}} = datos;
    
    return (
        <>
            <ContBanner>
                <Btn1 to={url}><div>¡CONTÁCTANOS!</div></Btn1>
                <ContImg>
                    <img src={image} alt={image} />
                </ContImg>
                <ContParagraph>
                    <p className='paragraph1'>¿Necesitas más información?</p>
                    <p className='paragraph2'>CONOCE MÁS SOBRE <br /> NUESTROS PRODUCTOS Y SERVICIOS</p>
                    <Btn2 to={url}>¡CONTÁCTANOS!</Btn2>
                </ContParagraph>
            </ContBanner>
        </>
    )
}

export default BannerContactanos