import React, { useEffect, useState } from 'react'
// react router dom
import { useLocation } from 'react-router-dom';
// import data
import { data } from '../data/data';
// import library helmet
import { Helmet } from 'react-helmet';
//styles
import { ConCardsServ, ContCard, ContImg, ContParagraph } from '../styles/Servicios';
// import component
import BannerContactanos from '../components/BannerContactanos';

const Servicios = () => {

    //get data
    const [datos] = data;
    //destructuring data
    const { servicios: { dropDownMenu } } = datos;
    const card = Object.values(dropDownMenu)

    // Obtenemos la URL actual utilizando el hook useLocation
    const location = useLocation();

    const [dataUrl, setDataUrl] = useState('');
    const detalles = dataUrl;
    // console.log(dataUrl)

    const { helmet, name, paragraph1, img } = detalles;

    useEffect(() => {
        const updateData = async () => {
            const found = card.find(element => element.url === location.pathname);
            setDataUrl(found)
        };
        // Call data update function when URL changes
        updateData();

    }, [location.pathname]);

    if (!dataUrl) {
        // console.log('No data')
        return () => {
            <div>Cargando Data.....</div>
        }
    }

    return (
        <>
            <Helmet><title>{helmet} – FACCEL S.A.S.</title></Helmet>
            <h1>{helmet}</h1>


            <ConCardsServ>
                <ContCard>
                    <ContParagraph>
                        <p className='title'>
                            {name.split('\n').map((line, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                )
                            })}</p>
                        <p className='paragraph'>{paragraph1}</p>
                    </ContParagraph>
                    <ContImg>
                        <img src={require(`../images/${img}`)} alt={helmet} />
                    </ContImg>
                    <p className='title2'>{helmet}</p>
                </ContCard>
            </ConCardsServ>
            <BannerContactanos />
        </>
    )
}

export default Servicios