import React from 'react'
// import dinamic helmet
import { Helmet } from 'react-helmet'
//import components
import SecMap from '../components/SecMap'
import CardsLanding from '../components/CardsLanding'
import BannerPrincipal from '../components/BannerPrincipal'
import SecProductos from '../components/SecProductos'
import { data } from '../data/data'


const Home = () => {

    //get data
    const [datos] = data;
    //destructuring data
    const { inicio: { metaHelmet: { meta0, meta1, link } } } = datos;
    //get elements of object
    const dataMeta0 = Object.values(meta0);
    const dataMeta1 = Object.values(meta1);


    return (
        <>
            <Helmet>
                <title>FACCEL S.A.S.</title>
                {dataMeta0.map((item) => <meta name={item.name} content={item.content} />)}
                {dataMeta1.map((item) => <meta property={item.name} content={item.content} />)}
                <link rel={link.rel} href={link.href} />
            </Helmet>
            <h1>Faccel Ingenieria Electrica</h1>
            <BannerPrincipal />
            <SecProductos />
            <CardsLanding />
            <SecMap />
        </>
    )
}

export default Home