import styled from "styled-components";

export const ContBanner = styled.div`
    height: auto;
    margin-top:clamp(110px, 9vw, 150px);
    overflow: hidden;
    width: 100%;
    .BannerPages1,
    .BannerPages2,
    .BannerPages3
    {
        width: 100%;
        object-fit: cover;
        object-position: center center;
    }
    @media(min-width:1200px){
        .BannerPages1{
            display: block;
        }
        .BannerPages2{
            display: none;
        }
        .BannerPages3{
            display: none;
        }
    }
    @media(min-width:601px) and (max-width:1200px) {
        .BannerPages1{
            display: none;
        }
        .BannerPages2{
            display: block;
        }
        .BannerPages3{
            display: none;
        }
    }
    @media (max-width:600px) {
        .BannerPages1{
            display: none;
        }
        .BannerPages2{
            display: none;
        }
        .BannerPages3{
            display: block;
        }
    }
`