import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { data } from '../data/data';
import { ContPagesEmpresa } from '../styles/NavEmpresa';
import { CardsProd, ConImgCel, ConImgProd, ConProducto, ContCardCel, ContCardsCel, ContCardsProd, ContImgCelda, ParagraphProd, TitleProductos } from '../styles/Productos';
import { useLocation } from 'react-router-dom';
import BannerContactanos from '../components/BannerContactanos';

const Encerramientos = () => {

    //Get data
    const [datos] = data;
    const { productos: { encerramientos: { cardsEncerramientos } } } = datos
    // extract values of cards
    const card = Object.values(cardsEncerramientos);

    //.........................
    // Obtenemos la URL actual utilizando el hook useLocation
    const location = useLocation();

    const [dataUrl, setDataUrl] = useState('');
    const detalles = dataUrl;
    // console.log(dataUrl)
    const { helmet, textP, paragraph, img, imgBack } = detalles;

    useEffect(() => {
        const updateData = async () => {
            const found = card.find(element => element.url === location.pathname);
            setDataUrl(found)
        };
        // Call data update function when URL changes
        updateData();

    }, [location.pathname]);

    if (!dataUrl) {
        // console.log('No data')
        return () => {
            <div>Cargando Data.....</div>
        }
    }

    return (
        <>
            <Helmet><title>{helmet} – FACCEL S.A.S.</title></Helmet>
            <h1>{helmet}</h1>


            <ContCardsCel>
                <ConImgCel>
                    <img src={require(`../images/${imgBack}`)} alt={imgBack} />
                </ConImgCel>
                <ContCardCel>
                    <ParagraphProd>
                        <div>
                            <p className='title_card'>{textP}</p>
                            <p className='paragraph_card'>{paragraph.split('\n').map((line, i) => {
                                return (
                                    <li key={i}>
                                        {line}
                                        <br />
                                    </li>
                                )
                            })}</p>
                        </div>
                    </ParagraphProd>
                    <ContImgCelda>
                        <img src={require(`../images/${img}`)} alt={helmet} />
                    </ContImgCelda>
                    <p className='title_card_2'>{textP}</p>
                </ContCardCel>
            </ContCardsCel>

            <BannerContactanos />
        </>
    )
}
export default Encerramientos