import styled from "styled-components";

export const ContPoliticas = styled.div`
    /* border: 1px solid red;//........... */
    width: 90%;
    height: auto;
    margin: 0 auto 4em;
    background-color: var(--color2);
    div{
        padding: 2.5vw 4vw;
    }
`
export const TitlePoliticas = styled.p`
    /* border: 2px solid green;//.................... */
    text-align:center;
    font-size: clamp(25px,3vw, 57px);
    font-weight: 900;
    color: var(--faccel1);
    margin: 1em 0;
`
export const ParagraphPol = styled.p`
    font-size: clamp(12px, 1.3vw, 25px);
    text-align: justify;
`