import React from 'react'
//import react router dom
import { Link } from 'react-router-dom';
//styles
import { ContBannersMap  } from '../styles/Address';
//import images
import ImgLocal from '../images/BannerMap/BANNER-LOCAL.png'
import ImgPlanta from '../images/BannerMap/BANNER-PLANTA.png'



const SecMap = (props) => {

    return (
        <>
            <ContBannersMap>
                <div>
                    <Link target='_blank' to='https://goo.gl/maps/ZgjA3sDKixBuoxcy6'><img src={ImgLocal} alt="imagen local comercial faccel" /></Link>
                    <Link target='_blank' to='https://goo.gl/maps/oQ77qbMAY8LPwYi57'><img src={ImgPlanta} alt="imagen planta produccion faccel" /></Link>
                </div>
            </ContBannersMap>
        </>
    )
}

export default SecMap