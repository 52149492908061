import styled from "styled-components";



export const ContBannersMap = styled.section`
    /* border: 1px solid red;//.......... */
    margin-bottom: 3em;
    div{
        /* border: 1px solid red;//.......... */
        margin: auto;
        width: 90%;
        display: flex;
        @media (max-width:600px) {
            flex-direction: column;
        }
        a{
            padding: 1em;
            width: 50%;            
            @media (max-width:600px) {
                width: 90%;
            }
            img{
                width: 100%;
                opacity: 0.85;
                &:hover{
                    transition: 200ms;
                    opacity: 1;
                }
            }
        }
    }
`