import { Link } from "react-router-dom";
import styled from "styled-components";


export const ContCertificaciones = styled.div`
    /* border: 1px solid blue;//......................... */
    background-color: var(--color5);
    margin-top: 2vw;
`
export const Title = styled.div`
    /* border: 2px solid green;//.................... */
    text-align:center;
    padding: 0 0 2em 0;
    p{
        font-size: clamp(25px,3.8vw, 67px);
        font-weight: 700;
        color: var(--faccel1);
    }
`
export const SubTitle = styled.div`
    /* border: 2px solid green;//.................... */
    text-align:center;
    font-size: clamp(22px,3vw, 40px);
    color: var(--color9);
    width: 80%;
    margin: auto;
`
export const BtnWhatsapp = styled(Link)`
    text-align: center;
    margin: 3.5em auto;
    text-decoration: none;
    width: 90%;
    display: grid;
    place-items: center;
    div{
        /* border: 1px solid red;//.................... */
        width: 90%;
        max-width: 600px;
        padding: .8em;
        color: white;
        font-weight: 700;
        font-size: clamp(18px,3vw, 25px);
        background-color: var(--faccel1);
        opacity: 0.85;
        &:hover{
            opacity: 1;
        }
        &:active{
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88); 
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88);
        }
    }
`
export const ContCards = styled.div` 
    /* border: 1px solid red;//....................... */
    text-align: center;
    height: max-content;
    padding: 2rem 0;
`
export const ContImgCertif = styled.div`
    /* border: 1px solid red;//................. */
    display: flex;
    justify-content: center;
    margin: auto ;
    width:90%;
    @media (max-width:1000px) {
        width: 100%;
    }
`
export const ImgCertif = styled.div`
    /* border: 1px solid red;//................. */
    width: 25%;
    aspect-ratio: 1/1;
    overflow:visible;
    img{
        width: 100%;
    }
    @media (max-width:1000px) {
        width: 100%;
    }
`
export const ContCertfContact = styled.div `
    /* border:2px solid blue;//.................. */
    width: 90%;
    height: max-content;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    @media (max-width:1270px) {
        margin-top: 10px;
        margin-bottom: 50px;
    }
    @media (min-width:1730px) {
        height: 870px;
    }
`
// stiles slider and arrow
export const ContSliderCert = styled.div`
    /* border: 1px solid red;//...................... */
    width: 100%;
    margin: auto;
    height: auto;
    background: rgb(33,143,70);
    background: linear-gradient(0deg, rgba(33,143,70,1) 57%, rgba(255,255,255,1) 57%);
    .slick-slider{
        /* border: 1px solid red;//...................... */
    }
    .slick-prev, .slick-next{
        width: 30px!important;

    }
    .slick-prev::before, .slick-next::before{
        /* border: 1px solid red;//............. */
        color: var(--color1)!important;
        font-size: 50px!important;
        opacity: 1!important;
        border-radius: 50%;
    }

    .slick-prev{
        left: -1px;
        z-index: 1;
        @media(min-width:830px){
            left: -30px;
        }
    }
    .slick-next{
        right: 10px;
        z-index: 1;
        @media(min-width:830px){
            right: -10px;
        }
    }
`
export const SubContSlider = styled.div`
    width: 90%;
    margin: auto;
    .AuxParagraph{
        /* border: 1px solid red;//.................... */
        font-size: clamp(22px, 2vw, 40px);
        color: white;
        width: 80%;
        margin: auto;
        padding: 1em 0 2em 0;

    }
`
export const CardCert = styled.div`
    /* border: 1px solid red;//.................... */
    margin: 1em 0;
    width:clamp(200px, 20vw, 340px)!important;
    height: auto;
    span{
    /* border: 1px solid red;//.................... */
        p{
        padding:1em 0;
        font-size: clamp(16px,1.4vw, 22px);
        font-weight: 900;
        line-height: 1.3em;
        color: var(--faccel1);
        @media(max-width:950px){
            font-size: 14px;
            }
        }
    }
    img{
        width: 100%;
        margin: auto;
    }
`













