import React from 'react'
//import data
import { data } from '../data/data'
//styles
import { ContPoliticas, ParagraphPol, TitlePoliticas } from '../styles/PoliticaSalud';

const PoliticaSalud = () => {

    //get data
    const [datos] = data;
    //destructuring data
    const { politicas: { name, paragraph } } = datos;

    return (
        <>
            <ContPoliticas>
                <div>
                    <TitlePoliticas>{name}</TitlePoliticas>
                    <ParagraphPol>{paragraph.split('\n').map((line, i) => {
                        return (
                            <React.Fragment key={i}>
                                {line}
                                <br />
                            </React.Fragment>
                        )
                    })}</ParagraphPol>
                </div>
            </ContPoliticas>
        </>
    )
}

export default PoliticaSalud