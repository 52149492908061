import React from "react";
// import data 
import { data } from "../data/data";
//styled
import { ContBanner } from "../styles/BannerPrincipal";


const BannerPrincipal = () => {

    // get data
    const [datos] = data;
    //destructuring data
    const { inicio: { banner1, banner2, banner3 } } = datos;

    return (
        <>
            <ContBanner >
                <img className='BannerPages1' src={require(`../images/${banner1}`)} alt={banner1} />
                <img className='BannerPages2' src={require(`../images/${banner2}`)} alt={banner2} />
                <img className='BannerPages3' src={require(`../images/${banner3}`)} alt={banner3} />
            </ContBanner >
        </>
    );
}
export default BannerPrincipal;