import styled from "styled-components";

export const ContSecProductos = styled.div` 
    /* border: 1px solid red;//....................... */
    text-align: center;
    /* margin: 2rem 0; */
    height: max-content;
    padding: 2rem 0;
`
export const CardProductos = styled.div`
    /* border: 1px solid red;//.................... */
    padding-bottom:1em;
    margin: 1em 0;
    width:clamp(260px, 21vw, 440px)!important;
    height: 29vw;
    background: var(--faccel4);
    @media (max-width: 870px) {
        height: 330px;
    }
    span{
        p{
        padding:1em 0;
        font-size: clamp(16px,1.7vw, 30px);
        font-weight: 500;
        line-height: 1.3em;
        color: var(--color4);
        @media(max-width:950px){
            font-size: 14px;
            }
        }
    }
    
    a{
        margin: auto;
        width: 70%;
        height: 3vw;
        min-height: 40px;
        font-size: clamp(18px,1.3vw,25px);
        font-weight: 700;
        text-decoration:none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--faccel1);
        color: white;
        transition: 30ms;
        opacity: 0.85;
        @media(max-width:700px){
            font-size: 16px;
        }
        &:hover{
            opacity: 1;
        }
        &:active{
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88); 
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88);
        }
    }
    
`
export const ContImg = styled.div`
    /* border: 1px solid red;//.................. */
    overflow: hidden;
    width: 100%;
    height: 60%;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
    }
`
export const TitleSectionP = styled.h1`
    color: var(--faccel1);
    font-size: clamp(28px, 3.8vw, 70px);
    font-weight: 900;
    padding:1rem 0 1rem 0;
    @media(max-width:710px){
        font-size: 8vw;
        width: 90%;
        margin: auto;
    }
`
// stiles slider and arrow
export const ContSliderProd = styled.div`
    /* border: 1px solid red;//...................... */
    width: 90%;
    margin: auto;
    height: auto;
    .slick-slider{
        /* border: 1px solid red;//...................... */
    }
    .slick-prev, .slick-next{
        width: 30px!important;

    }
    .slick-prev::before, .slick-next::before{
        /* border: 1px solid red;//............. */
        color: var(--faccel1)!important;
        font-size: 50px!important;
        opacity: 1!important;
        border-radius: 50%;
    }

    .slick-prev{
        left: -1px;
        z-index: 1;
        @media(min-width:830px){
            left: -30px;
        }
    }
    .slick-next{
        right: 10px;
        z-index: 1;
        @media(min-width:830px){
            right: -10px;
        }
    }
`