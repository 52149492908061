export const data = [
  {
    inicio: {
      id: "inicioNav",
      name: "Inicio",
      url: "/home",
      logoNav: "Logo-itic.svg",
      email: "Comercial@faccel.com",
      phone: "+57 311 2600365",
      whatsappUrl: "https://api.whatsapp.com/send?phone=573112600365",
      whatsappImg: "boton.png",
      address2: "Ciudadela Industrial, Duitama, Boyacá",
      address: "Calle 12 #16-47,  Duitama, Boyacá",
      addressBuilding: "Duitama, Boyacá",
      facebookUrl: "https://www.facebook.com/Faccelsas",
      instagramUrl: "https://www.instagram.com/faccelsas/",
      twitterUrl: "https://twitter.com/",
      linkedInUrl: "https://www.linkedin.com/",
      banner1: 'banners-ppales/banner-ppal-1.png',
      banner2: 'banners-ppales/banner-ppal-2.png',
      banner3: 'banners-ppales/banner-ppal-3.png',
      metaHelmet:{
        meta0:{
          meta_0:{
            name:'description',
            content:'Fabricación y comercialización de tableros para control, distribución y medida de la energía eléctrica, bancos para corrección de factor de potencia, tableros para transferencias automáticas, celdas de media tensión y postes para alumbrado público.',
          },
          meta_1:{
            name:'keywords',
            content:'Celdas de media tensión, Tableros de baja tensión, postes para alumbrado público, encerramientos metálicos, estudio de calidad de energía',
          }
        },
        meta1:{
          meta_0:{
            name:'og:type',
            content:'website',
          },
          meta_1:{
            name:'og:title',
            content:'Faccel – Ingeniería eléctrica',
          },
          meta_2:{
            name:'og:description',
            content:'Celdas de media tensión, Tableros de baja tensión, postes para alumbrado público, encerramientos metálicos, estudio de calidad de energía',
          },
          meta_3:{
            name:'og:url',
            content:'https://www.faccel.com/',
          },
          meta_4:{
            name:'og:site_name',
            content:'Faccel Ingeniería eléctrica',
          },
        },
        link:{
          rel:'canonical',
          href:'https://www.faccel.com/',
        }
      }
    },
    empresa: {
      id: "empresaNav",
      name: "Nosotros",
      dropDownMenu: {
        nosotros: {
          key: 1,
          id: "nosotros",
          name: "Nosotros",
          nameNav2: "Acerca de nosotros",
          nameFooter: "Acerca de nosotros",
          helmet:'Acerca de nosotros',
          url: "/acerca-de-nosotros",
          img: "Nosotros/img-empresa.png",
          paragraph1:
            "Somos una empresa con Orientación social, buscamos profesionales comprometidos con nuestros valores empresariales en búsqueda de EXCELENCIA OPERACIONAL E INTEGRIDAD en nuestros servicios.",
          paragraph2:
            "Nacimos hace 13 años como empresa de filial de un grupo alemán y el 2023 como empresa nacional con presencia en varios países de Suramérica y Centroamérica.",
          paragraph3:
            "Nuestra experiencia en proyectos de gran envergadura a nivel nacional e internacional nos permite ofrecer confianza, agilidad, integridad y seguridad en servicios de Inspección, certificación y Ensayos, basamos nuestros conceptos en estándares técnicos nacionales e internacionales, contamos con profesionales con calificaciones técnicas internacionales, ofrecemos el desarrollo de soluciones digitales a la medida de nuestros clientes agilizando la toma acertada de decisiones y ahorro de recursos .",
          titleSeccion: "¿POR QUÉ NOSOTROS?",
          cards: {
            card0: {
              id: "card0",
              img: "Nosotros/quienes-4.jpg",
              title: "¿Quiénes somos?",
              paragraph:
                "Empresa Orgullosamente Boyacense con mas de 7 años de experiencia en la fabricación y comercialización de tableros para control, distribución y medida de la energía eléctrica, bancos para corrección de factor de potencia, tableros para transferencias automáticas, celdas de media tensión y postes para alumbrado publico.\n\n\nTrabajamos constantemente en el mejoramiento de la calidad de nuestros productos con el propósito de aumentar la satisfacción de nuestros clientes y así lograr un rendimiento económico que nos permita permanecer en el mercado, asegurando el constante desarrollo personal y profesional de nuestro colaboradores.",
            },
            card1: {
              id: "card1",
              img: "Nosotros/quienes-2.jpg",
              title: "Visión",
              paragraph:
                "Para el año 2025 seremos una empresa que brinda soluciones eléctricas para proyectos de construcción, industria y minería a través de la fabricación de tableros de uso eléctrico; siempre orientados en ofrecer productos que cumplan con la reglamentación legal Colombiana y los requisitos del cliente.",
            },
            card2: {
              id: "card2",
              img: "Nosotros/quienes-3.jpg",
              title: "Misión ",
              paragraph:
                "En Faccel ingeniería eléctrica se trabaja todos los días para superar las expectativas de los clientes, a través del suministro de tableros de uso eléctrico; trabajamos par ser reconocidos como la mejor opción en el diseño, fabricación y comercialización de celdas y tableros, contamos con un talento humano responsable, comprometido y con una solida convicción del cumplimiento de los requisitos legales y reglamentarios aplicables en Colombia a través del reglamento técnico de instalaciones eléctricas RETIE.",
            },
            card3: {
              id: "card3",
              img: "Nosotros/quienes-1.jpg",
              title: "Objetivos de calidad ",
              paragraph:
                "- Generar la rentabilidad que nos permita sostenibilidad en el mercado.\n\n- Suministrar productos con altos estándares de calidad y cero defectos.\n\n- Lograr con nuestros productos la plena satisfacción de nuestros clientes.\n\n- Mejorar la eficiencia de los procesos operativos.\n\n- Capacitar y entrenar permanentemente a nuestros colaboradores.",
            },
          },
        },
        politicas: {
          key: 7,
          id: "politicas",
          name: "Políticas De Seguridad Y Salud En El Trabajo",
          nameFooter: "políticas de seguridad y salud en el trabajo",
          nameNav2: "políticas de seguridad \ny salud en el trabajo",
          helmet: "políticas de seguridad y salud en el trabajo",
          url: "/politicas",
          banner1: "",
          cards: {
            card0: {
              id: "1.-POLITICA-DE-PROTECCION-DE-DATOS-PERSONALES.pdf",
              img: "",
              text: "POLITICA DE  \nPROTECCION DE  \nDATOS \nPERSONALES ",
            },
            card1: {
              id: "politicas-card1",
              img: "",
              text: "POLÍTICA DE IMPARCIALIDAD, \nINDEPENDENCIA Y \nCONFIDENCIALIDAD",

            },
            card2: {
              id: "politicas-card2",
              img: "",
              text: "POLÍTICA DE IMPARCIALIDAD, \nINDEPENDENCIA Y \nCONFIDENCIALIDAD",

            },
            card3: {
              id: "politicas-card3",
              img: "",
              text: "POLÍTICA DE IMPARCIALIDAD, \nINDEPENDENCIA Y \nCONFIDENCIALIDAD",

            },
            card4: {
              id: "politicas-card4",
              img: "",
              text: "POLÍTICA DE IMPARCIALIDAD, \nINDEPENDENCIA Y \nCONFIDENCIALIDAD",

            },
            card5: {
              id: "politicas-card5",
              img: "",
              text: "POLÍTICA DE IMPARCIALIDAD, \nINDEPENDENCIA Y \nCONFIDENCIALIDAD",

            },
            card6: {
              id: "politicas-card6",
              img: "",
              text: "POLÍTICA DE IMPARCIALIDAD, \nINDEPENDENCIA Y \nCONFIDENCIALIDAD",

            },
            card7: {
              id: "politicas-card7",
              img: "",
              text: "POLÍTICA DE IMPARCIALIDAD, \nINDEPENDENCIA Y \nCONFIDENCIALIDAD",

            },
            card8: {
              id: "politicas-card8",
              img: "",
              text: "POLÍTICA DE IMPARCIALIDAD, \nINDEPENDENCIA Y \nCONFIDENCIALIDAD",

            },
          },
        },
        
      },
    },
    servicios: {
      id: "serviciosNav",
      nameS: "Servicios",
      title: "Servicios",
      img:'/servicios.jpg',
      paragraph:'Estudio de calidad \nde energía\n\n',
      urlS: "/servicios/estudio-de-calidad-de-energia",
      subTitle:
        "Nuestra experiencia en proyectos de gran envergadura a nivel nacional e internacional nos permite ofrecer confianza, agilidad, integridad y seguridad en servicios de INSPECCIÓN, CERTIFICACIÓN Y ENSAYOS.",
      dropDownMenu: {
        card0: {
          id: "estudio-de-calidad-de-energia",
          helmet: 'Estudio De Calidad De Energía',
          img: "medidor.png",
          url: "/servicios/estudio-de-calidad-de-energia",
          name: "Estudio De\nCalidad De\nEnergía",
          text2:  "ESTUDIO DE CALIDAD DE ENERGÍA",
          paragraph1:'Lo que no se mide no se controla, en Faccel contamos con los equipos y personal idóneo para evaluar y monitorear variables eléctricas como: tensión, corriente, potencia, armónicos y factor de potencia y así diseñar soluciones integrales para evitar daños a sus equipos o cobros innecesarios en su factura mensual.',
          metaHelmet:{
            meta0:{
              meta_0:{
                name:'',
                content:'',
              },
              meta_1:{
                name:'',
                content:'',
              }
            },
            meta1:{
              meta_0:{
                name:'',
                content:'',
              },
              meta_1:{
                name:'',
                content:'',
              },
              meta_2:{
                name:'',
                content:'',
              },
              meta_3:{
                name:'',
                content:'',
              },
              meta_4:{
                name:'',
                content:'',
              },
            },
            link:{
              rel:'',
              href:'',
            }
          }
        },
      },
    },
    productos: {
      id: "productos",
      name: "Nuestros productos",
      url: "/productos",
      key: 3,
      title: "Conoce todos nuestros productos",
      paragraph:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      nameNav2: "Acreditaciones",
      nameFooter: "Acreditaciones",
      img: "banner-2.png",
      banner1: "",
      celdas:{
        idC: "celdas",
        nameC: "Celdas Y Tableros",
        paragraph:'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        url: "/celdas",
        img:'banner-5.png',
        cardsCeldas:{
          card0:{
            id:'celdas-en-media-tension',
            name:'Celdas en media tensión',
            url: "/celdas/celdas-en-media-tension",
            helmet: 'Celdas en media tensión',
            imgBack:'celdas_tableros/fondo-celdas.jpg',
            img: "celdas_tableros/img-vacia.png",
            textP:"Celdas en media tensión hasta 17500 voltios, con aislamiento en aire",
            paragraph:'Celda entrada- salida y protección \nCelda entrada – salida \nCelda de protección \nCelda de medida directa',
            metaHelmet:{
              meta0:{
                meta_0:{
                  name:'description',
                  content:'Fabricación y comercialización de Celdas en media tensión hasta 17500 voltios, con aislamiento en aire.',
                },
                meta_1:{
                  name:'keywords',
                  content:'Celdas de media tensión, Celda entrada- salida y protección, Celda entrada – salida, Celda de protección, Celda de medida directa',
                }
              },
              meta1:{
                meta_0:{
                  name:'og:type',
                  content:'website',
                },
                meta_1:{
                  name:'og:title',
                  content:'Faccel – Ingeniería eléctrica',
                },
                meta_2:{
                  name:'og:description',
                  content:'Fabricación y comercialización de Celdas en media tensión hasta 17500 voltios, con aislamiento en aire',
                },
                meta_3:{
                  name:'og:site_name',
                  content:'Faccel Ingeniería eléctrica',
                },
              },
              link:{
                rel:'canonical',
                href:'https://www.faccel.com/celdas/celdas-en-media-tension',
              }
            }
          },
          card1:{
            id:'tableros-en-baja-tension',
            name:'Tableros en baja tensión',
            url: "/celdas/tableros-en-baja-tension",
            helmet: 'Tableros en baja tensión',
            imgBack:'celdas_tableros/fondo-tableros.jpg',
            img: "celdas_tableros/img-vacia.png",
            textP:'Tableros en baja tensión hasta 4000 amperios de corriente nominal y hasta 40 kA de corriente de corto.',
            paragraph:'Tablero para corrección de factor de potencia \nTablero para transferencia automática \nArmarios para alojar medidores – medida directa \nEquipo de medida semidirecta \nTablero general de acometidas \nTablero de distribución \nControles para alumbrado \nTablero para provisional de obra',
            metaHelmet:{
              meta0:{
                meta_0:{
                  name:'description',
                  content:'Fabricación y comercialización Tableros en baja tensión hasta 4000 amperios de corriente nominal y hasta 40 kA de corriente de corto.',
                },
                meta_1:{
                  name:'keywords',
                  content:'Tablero para corrección de factor de potencia, Tablero para transferencia automática, Armarios para alojar medidores – medida directa, Equipo de medida semidirecta, Tablero general de acometidas, Tablero de distribución, Controles para alumbrado, Tablero para provisional de obra',
                }
              },
              meta1:{
                meta_0:{
                  name:'og:type',
                  content:'website',
                },
                meta_1:{
                  name:'og:title',
                  content:'Faccel – Ingeniería eléctrica',
                },
                meta_2:{
                  name:'og:description',
                  content:'Fabricación y comercialización Tableros en baja tensión hasta 4000 amperios de corriente nominal y hasta 40 kA de corriente de corto.',
                },
                meta_3:{
                  name:'og:site_name',
                  content:'Faccel Ingeniería eléctrica',
                },
              },
              link:{
                rel:'canonical',
                href:'https://www.faccel.com/celdas/tableros-en-baja-tension',
              }
            }
          },
          card3:{
            id:'postes-para-alumbrado-publico',
            name:'Postes para alumbrado público',
            url: "/celdas/postes-para-alumbrado-publico",
            helmet: 'Postes para alumbrado público',
            imgBack:'celdas_tableros/fondo-postes.jpg',
            img: "celdas_tableros/img-postes.png",
            textP:'Postes para alumbrado público',
            paragraph:'Postes para alumbrado público certificados bajo el reglamento técnico de iluminación y alumbrado público RETILAP, según requerimientos propios del diseño.',
            metaHelmet:{
              meta0:{
                meta_0:{
                  name:'description',
                  content:'Fabricación y comercialización de Celdas en media tensión hasta 17500 voltios, con aislamiento en aire.',
                },
                meta_1:{
                  name:'keywords',
                  content:'Postes para alumbrado público',
                }
              },
              meta1:{
                meta_0:{
                  name:'og:type',
                  content:'website',
                },
                meta_1:{
                  name:'og:title',
                  content:'Faccel – Ingeniería eléctrica',
                },
                meta_2:{
                  name:'og:description',
                  content:'Postes para alumbrado público',
                },
                meta_3:{
                  name:'og:site_name',
                  content:'Faccel Ingeniería eléctrica',
                },
              },
              link:{
                rel:'canonical',
                href:'https://www.faccel.com/celdas/postes-para-alumbrado-publico',
              }
            }
          },
        }
      },
      encerramientos:{
        idE: "encerramientos",
        nameE: "Encerramientos",
        url: "/encerramientos",
        img:'banner-5.png',
        cardsEncerramientos:{
          card2:{
            id:'encerramientos-metalicos',
            name:'Encerramientos metálicos',
            imgBack:'encerramientos/fondo-encerramientos.jpg',
            img: "encerramientos/encerramiento.png",
            url: "/encerramientos/encerramientos-metalicos",
            helmet: 'Encerramientos metálicos',
            textP:'Encerramientos metálicos para alojar equipos eléctricos IP 44 e IP 65',
            paragraph:'Encerramiento metálico para alojar equipos eléctricos y electrónicos con grado de protección IP 44 e IP 65, según requerimientos propios del proyecto',
          },
        } 
      },
      documentOnac: {
        cards: {
          card4: {
            id: "cardGest",
            url: "/celdas/celdas-en-media-tension",
            img: "encerramiento.png",
            name: "SISTEMAS DE \nGESTIÓN",
            acreditacion: 'gestion',
            text0: 'En ITICCOL contamos con acreditación ONAC, vigente a la fecha con código de acreditación:',
            textP:"Celdas de \nmedia tensión",
              paragraph:'Encerramiento metalico para alojar equipos electricos y electronicos con grado de proteccion IP 44 e IP 65, segun requerimientos propios del proyecto.',
            
          },
          card3: {
            id: "cardProd",
            url: "/celdas/tableros-en-baja-tension",
            img: "tableros.png",
            name: "PRODUCTOS",
            acreditacion: 'productos',
            text0: 'En ITICCOL contamos con acreditación ONAC, vigente a la fecha con código de acreditación:',
            textP:
              "Tableros eléctricos \nde Baja tensión",
              paragraph:'- Tablero para correccion de factor de potencia\n\n- Tablero para transferencia automatica\n\nArmarios para alojar medidores - medida directa\n\n- Equipo de medida semidirecta\n\n- Tablero general de acometidas\n\nTablero de distribucion\n\n- Controles para alumbrado\n\n- Tablero para provisional de obra',
            
          },
          card1: {
            id: "cardInspec",
            url: "/encerramientos/encerramientos-metalicos",
            img: "celdas.png",
            name: "INSPECCION",
            acreditacion: 'inspeccion',
            text0: 'En ITICCOL contamos con acreditación ONAC, vigente a la fecha con código de acreditación:',
            textP:
              "Encerramientos \ny cajas",
            paragraph:'- Celda entrada - salida y proteccion\n\n- Celda entrada - salida\n\n- Celda de proteccion\n\n- Celda de media directa',
          },
          
          
          card2: {
            id: "cardPers",
            url: "/celdas/postes-para-alumbrado-publico",
            img: "postes.png",
            name: "PERSONAS",
            acreditacion: 'personas',
            text0: 'En ITICCOL contamos con acreditación ONAC, vigente a la fecha con código de acreditación:',
            textP:
              "Postes para \nalumbrado público",
              paragraph:'Postes para alumbrado publico certificados bajo el reglamento tecnico de iluminacion y alumbrado publico RETILAP, segun requerimientos propios del diseño.',
          },
        },
      },
    },
    certificaciones: {
      id: "certificaciones",
      name: "Certificados",
      img:'certificados.png',
      title: "Certificados",
      url:'/certificaciones',
      titleForm:'Solicita el certificado',
      subTitleForm:'Diligencia el formulario y una vez completados tus datos, te enviaremos un correo al e-mail registrado donde podrás descargar el certificado de nuestros productos los cuales cumplen certificado RETIE.',
      subTitle: "Contamos con la certificación de producto RETIE, con el siquiente alcance:",
      paragraph1:'El certificado de conformidad de producto RETIE, además de ser un requisito legal representa una ventaja competitiva; amigo electricista utilice en sus obras tableros certificados y así evite reprocesos durante la inspección y certificación RETIE',
      helmet: "Certificaciones",
      paragraph:'Nuestros productos han sido evaluados y certificados por un ente de tercera parte, pueden ser consultados en www.sicerco.sic.gov.co, o en su efecto solicítelos al 3112600365',
      metaHelmet:{
        meta0:{
          meta_0:{
            name:'description',
            content:'Contamos con la certificación de producto RETIE, con el siquiente alcance: Celdas de media tensión, Tableros en baja tensión, Encerramientos y cajas, postes para alumbrado público.',
          },
          meta_1:{
            name:'keywords',
            content:'Postes para alumbrado público',
          }
        },
        meta1:{
          meta_0:{
            name:'og:type',
            content:'website',
          },
          meta_1:{
            name:'og:title',
            content:'Faccel – Ingeniería eléctrica',
          },
          meta_2:{
            name:'og:description',
            content:'Certificados Retie',
          },
          meta_3:{
            name:'og:site_name',
            content:'Faccel Ingeniería eléctrica',
          },
        },
        link:{
          rel:'canonical',
          href:'https://www.faccel.com/certificaciones',
        }
      },
      cardsC: {
        card0: {
          id: "certificacion-sistemas-retie-retilap",
          img: "certificados.png",
          text1: "Certificaciones",
          text2:  "CERTIFICACIÓN INSTALACIONES RETIE/RETILAP",
          paragraph:
            'El certificado de conformidad de producto RETIE, ademas de ser un requisito legal representa una ventaja competitiva; amigo electricista utilice en sus obras tableros certificados y así evita re procesos durante la inspección y certificación RETIE. \n\nNuestros productos han sido evaluados y certificados por un ente de tercera parte, los certificados pueden ser consultados en www.sicerco.sic.gov.co, o en su efecto solicítelos al 311 2600365.\n',
          btn1: "SOLICITAR",
        },
      },
      cardsC_2:{
        card0:{
          name:'imagen certificado cajas',
          img:'certificado/certificado-cajas.jpg',
        },
        card1:{
          name:'imagen certificado celdas',
          img:'certificado/certificado-celdas.jpg',
        },
        card2:{
          name:'imagen certificado tableros',
          img:'certificado/certificado-tableros.jpg',
        },
      },
    },
    consulta: {
      id: "consultaNav",
        name: "CONSULTA TU CERTIFICADO",
          url: "",
            img: "modal-certificado.jpg",
              input: {
        input1: {
          id: "inputCertificado",
            label: "Categoría del certificado",
              placeHolder: "Categoría de servicio",
                type: "select",
                  select: {
            select1: "Sistemas de Gestión",
              select2: "Certificación de Producto",
                select3: "Servicios Industriales",
                  select4: "Certificación de Personas",
              },
        },
        input2: {
          id: "inputEmail",
            label: "E-mail",
              placeHolder: "usuario@correo.com",
                invalidEmail: "Ingresa un correo valido",
                  incorrectData: "Datos incorrectos",
                    className: "classAlert",
                      type: "email",
                        name: "email",
            },
        input3: {
          id: "inputEmailVerif",
            label: "Verifica tu E-mail",
              placeHolder: "usuario@correo.com",
                invalidEmail: "Ingresa un correo valido",
                  incorrectData: "Datos incorrectos",
                    className: "classAlert",
                      type: "email",
                        name: "email",
            },
        input4: {
          id: "certificateNumber",
            label: "Digita tu número de certificado",
              placeHolder: "0000000",
                invalidNumber: "Ingresa el numero del certificado",
                  incorrectData: "Numero incorrecto",
                    className: "classAlert",
                      type: "tel",
                        name: "certificate",
            },
      },
    },
    contacto: {
      id: "contactonNav",
      name: "Contáctanos",
      nameFooter: "Formulario de contacto",
      url: "/contacto",
      title: "¡CONTÁCTANOS!",
      subTitle: "CONOCE MÁS SOBRE \nNUESTROS SERVICIOS",
      btn: "COMUNÍCATE CON NUESTRO EQUIPO",
      btnMovile: "COMUNÍCATE",
      textBarraContac:"CONOCE MÁS SOBRE NUESTROS SERVICIOS Y LLEVA TU EMPRESA AL SIGUIENTE NIVEL",
      contactanos: {
        id: "page_contacto",
        title: "¡CONTÁCTANOS!",
        helmet: "Contactanos",
        labelSolic: "Solicitud",
        placeholder_solic: "Selecciona tu solicitud",
        solicitud: {
          0: "Felicitación",
          1: "Solicitud",
          2: "Apelación",
          3: "Queja o Reclamo",
          4: "Otro",
        },
        checkbox:
          'Al dar click al check, nos autoriza al tratamiento de sus datos personales. Con el fin de dar cumplimiento a lo establecido en la Ley 1581 de 2012 y de su Decreto reglamentario 1377 de 2013 Ver POLÍTICAS DE TRATAMIENTO DE DATOS PERSONALES',
          },
    },
    politicas: {
      id: "politicas",
      name: "POLÍTICA DEL SISTEMA DE SEGURIDAD Y SALUD EN EL TRABAJO",
      img:'',
      paragraph:'FACCEL INGENIERÍA ELÉCTRICA S.A.S, es una empresa dedicada al diseño, fabricación y comercialización de tableros para el control y medida de la energía eléctrica y estamos comprometidos con la implementación, desarrollo y fortalecimiento del Sistema de Gestión de Seguridad y Salud en el Trabajo, mediante procesos que sean seguros para todos nuestros trabajadores a través de las siguientes directrices:\n\n- identificación de los peligros, la evaluación, valoración de los riesgos y la implementación de las medidas de control necesarias para evitar la ocurrencia de incidentes, accidentes laborales y/o la presencia de enfermedades de tipo laboral.\n\n- Cumplir con la normatividad nacional vigente aplicable en materia de riesgos laborales.\n\n- Proteger la seguridad y salud de todos los trabajadores mediante la mejora continua de los procesos.\n\n- Asignar los recursos necesarios\n\n- Llevar a cabo actividades de promoción y prevención orientadas a mejorar el bienestar de los trabajadores.\n\nEsta política tiene alcance sobre todos los centros de trabajo y todos los trabajadores, independientemente de su forma de contratación o vinculación, incluyendo los contratistas y subcontratistas.\n',
    },
  },
];
