import styled from "styled-components";



export const ContCardsCel = styled.div`
    /* border: 1px solid blue;//.................. */
    margin-top:clamp(110px, 9vw, 150px);
    width:100%;
    height: auto;
`
export const ConImgCel = styled.div`
    /* border:2px solid red;//.................. */
    overflow: hidden;
    width: 100%;
    height: 100%;
    position:absolute;
    z-index: -999!important;
    img{
        /* width: 100%; */
        object-fit: cover;
        object-position: center center;
        @media (min-width:650px) {
            width: 100%;
            /* height: auto; */
        }
        @media (max-width:650px) {
            height: 100%;
            object-position: -100px center;
        }
    }
`
export const ContCardCel = styled.div`
    /* border:2px solid red;//.................. */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .title_card_2{
        font-size: clamp(28px, 4vw, 80px);
        font-weight: 800;
        margin-bottom: 1rem;
        color: var(--faccel1);
        display: none;
        @media (max-width:700px) {
            display: block;
        }
    }
    @media (max-width:700px) {
        flex-direction: column-reverse;
        align-items: center;
        width: 90%;
        text-align: center;
        margin: auto;
        padding: 1em 0;
    }
    @media (max-width:400px) {
        width: 90%;
    }
`
export const ContImgCelda = styled.div`
    /* border: 1px solid red;//............... */
    width: 35%;
    overflow: hidden;
    @media (max-width:650px) {
        width: 70%;
    }
    @media (max-width:400px) {
        width: 90%;
    }
    img{
        /* border: 1px solid red;//............... */
        width: 100%;
        object-fit: center;
        object-position: center center;
    }
`
//.........................................................
export const ConProducto = styled.div`
    margin-top: 150px;
    @media (max-width:1270px) {
        width: 72%;
        /* border: 2px solid green;//.................... */
        margin-bottom: 4.1rem;
        width: 100%;
        padding: 0;
        margin-bottom: 0;
    }
`
export const TitleProductos = styled.div`
    /* border: 2px solid green;//.................... */
    text-align:center;
    padding: 3em 0 2em 0;
    p{
        font-size: clamp(25px,3.8vw, 67px);
        font-weight: 900;
        color: var(--faccel1);
    }
`
export const ContCardsProd = styled.div`
    border: 1px solid red;//..................
    /* width: 40%; */
    /* padding: 1.5em 0; */
    margin: auto;
    align-items: center;
    @media (max-width:568px) {
        display: flex;
        flex-direction:column;
    }
    @media (max-width:1270px) {
        width: 90%;
        margin: auto;
    }
`
export const CardsProd = styled.div`
    /* border: 1px solid blue;//.................. */
    width: 50%;
    height: auto;
    min-height: 250px;
    display: flex;
    background-color: var(--color2);
    margin-bottom: 4em;
    @media (max-width:700px) {
        flex-direction: column;
        height: auto;
        width: 90%;
    }
    &:nth-child(even){
        flex-direction: row-reverse;
        @media (max-width:700px) {
            flex-direction: column;
        }
    }
`
export const ConImgProd = styled.div`
    /* border: 1px solid red;//................. */
    width: 50%;
    overflow: hidden;
    @media (max-width:700px) {
        height:200px;
        width: 100%;
    }
    img{
        /* border: 1px solid red;//................. */
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position:center center;
    }
`
export const ParagraphProd = styled.div`
    /* border: 1px solid blue;//................. */
    padding: 2.5vw 4vw;
    height: auto;
    width: 38%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width:700px) {
        padding: 1em;
        width: 85%;
        height:50%;
    }
    .title_card{
        font-size: clamp(20px, 2.5vw, 40px);
        font-weight: 800;
        margin-bottom: 1rem;
        color: var(--faccel1);
        @media (max-width:700px) {
            display: none;
        }
    }
    .paragraph_card{
        font-size: clamp(18px, 2vw, 30px);
        /* line-height:2.5em; */
        text-align: left;
    }
    .p_card{
        font-size: clamp(12px, 1.3vw, 25px);
        display: flex;
    }
    
`