import { Link } from "react-router-dom";
import styled from "styled-components";


export const ContBanner = styled.div`
    /* border: 1px solid blue;//............ */
    height: 24vw;
    background-color: var(--faccel4);
    display: flex;
    justify-content: space-around;
    @media (max-width:700px) {
        height: auto;
        padding-top: 1em;
        flex-direction: column-reverse;
    }
`
export const ContImg = styled.div`
    /* border: 1px solid red;//............ */
    overflow: hidden;
    height: 24vw;
    @media (max-width:700px) {
        display: flex;
        justify-content: center;
        height: 200px;
    }
    
    img{
        /* border: 1px solid red;//............ */
        height: 100%;
        object-fit: center;
        object-position: center center;
    }
`
export const ContParagraph = styled.div`
    /* border: 1px solid red;//............ */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .paragraph1{
        font-size: clamp(30px, 3vw, 60px);
        color: var(--faccel1);
        font-weight: 900;
        margin-bottom: 1em;
    }
    .paragraph2{
        /* border: 1px solid red;//............. */
        font-size: clamp(18px, 1vw, 30px);
        font-weight: 600;
        margin-bottom:1em;
        width: 90%;
    }
`
export const Btn1 = styled(Link)`
    display: none;
    font-size: clamp(25px, 2.5vw, 50px);
    text-decoration: none;
    margin:0 auto 1em;
    div{
        background-color: var(--faccel1);
        padding: .1em 1em;
        font-weight: 900;
        color: white;
        opacity: 0.85;
        &:hover{
        opacity: 1;
        }
        &:active{
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88); 
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88);
        }
    }
    @media (max-width:700px) {
        display: block;
        text-align: center;
    }
    
`
export const Btn2 = styled(Link)`
    display: block;
    font-size: clamp(25px, 2.5vw, 50px);
    text-decoration: none;
    font-weight: 900;
    color: white;
    padding: .1em 1em;
    background-color: var(--faccel1);
    opacity: 0.85;
    @media (max-width:700px) {
        display: none;
    }
    &:hover{
        opacity: 1;
    }
    &:active{
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88); 
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88);
    }
`