import { Link } from "react-router-dom";
import styled from "styled-components";

export const BtnLinkSec = styled(Link)`
    /* border: 1px solid red;//........... */
    text-decoration: none;
    div{
        margin-top: 1em;
        border: 2px solid var(--faccel1);//...........
        width: 10em;
        text-align: center;
        padding: 1em;
        background-color: var(--faccel1);
        color: var(--color5);
        font-weight: 700;
        opacity: 0.85;
        &:hover{
            opacity: 1;
        }
        &:active{
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88); 
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88);
        }
    }
`
export const ConCardsSec = styled.div`
    /* border: 1px solid red;//.................. */
    /* background-color: var(--color2); */
    width: 90%;
    padding: 1.5em 0;
    margin: auto;
    /* flex-direction: column; */
    align-items: center;
    @media (max-width:568px) {
        display: flex;
        flex-direction:column;
    }
    @media (max-width:1270px) {
        /* width: 90%; */
        margin: auto;
    }
`
export const CardsSec = styled.div`
    /* border: 1px solid blue;//.................. */
    width: 100%;
    height: auto;
    min-height: 250px;
    display: flex;
    background-color: var(--color2);
    margin-bottom: 2rem;
    @media (max-width:700px) {
        flex-direction: column;
        height: auto;
        width: 100%;
    }
    &:nth-child(even){
        flex-direction: row-reverse;
        @media (max-width:700px) {
            flex-direction: column;
        }
    }
`
export const ConImgSec = styled.div`
    /* border: 1px solid red;//................. */
    width: 50%;
    overflow: hidden;
    background-color: transparent;
    @media (max-width:700px) {
        height:200px;
        width: 100%;
    }
    img{
        /* border: 1px solid red;//................. */
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position:center center;
    }
`
export const ParagraphSec = styled.div`
    /* border: 1px solid blue;//................. */
    width: 50%;
    padding: 2.5vw 2.2vw;
    height: auto;
    display: flex;
    align-items: center;
    @media (max-width:700px) {
        padding: 1em;
        width: 90%;
        height:50%;
        margin: auto;
    }
    div{
        /* border: 1px solid blue;//................. */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title_card{
            font-size: clamp(25px, 3.8vw, 70px);
            font-weight: 800;
            margin-bottom: 1rem;
            color: var(--faccel1);
        }
        .p_card{
            font-size: clamp(18px, 2.5vw, 40px);
            display: flex;
            }
        }
`
export const CardSec = styled.div`
    /* border: 1px solid blue;//................. */
    width: 50%;
    padding: 2.5vw 2.2vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    @media (max-width:700px) {
        padding: 1em;
        width: 90%!important;
        height:50%;
        margin: auto;
    }
    .title_card2{
        /* border: 1px solid blue;//................. */
        font-size: clamp(28px, 4vw, 80px);
        text-align: center;
        font-weight: 800;
        margin-bottom: 1.5rem;
    }
    .p_card2{
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: clamp(16px, 2vw, 30px);
    }
`
export const ConImgSec2 = styled.div`
    /* border: 1px solid red;//................. */
    width: 50%;
    overflow: hidden;
    @media (max-width:700px) {
        height:fit-content;
        width: 70%;
        margin: auto;
    }
    img{
        /* border: 1px solid red;//................. */
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position:center center;
    }
`
export const CardsSec2 = styled.div`
    /* border: 1px solid red;//.................. */
    width: 100%;
    height: auto;
    min-height: 250px;
    display: flex;
    background: transparent linear-gradient(0deg, #98BF11 0%, #00913D 99%, #00913D 100%) 0% 0% no-repeat padding-box;
    margin-bottom: 2rem;
    @media (max-width:700px) {
        flex-direction: column;
        height: auto;
    }
    &:nth-child(even){
        flex-direction: row-reverse;
        @media (max-width:700px) {
            flex-direction: column;
        }
    }
`
export const BtnLinkSec2 = styled(Link)`
    /* border: 1px solid red;//........... */
    text-decoration: none;
    width: 100%;
    div{
        margin-top: 1em;
        text-align: center;
        padding: 1em;
        background-color: white;
        color: var(--color4);
        font-weight: 700;
        opacity: 1;
        &:hover{
            opacity: 0.90;
        }
        &:active{
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88); 
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.88);
        }
    }
`