import React, { useEffect, useState } from 'react'
//import react router dom
import { useLocation } from 'react-router-dom';
//import helmet
import { Helmet } from 'react-helmet'
//import data
import { data } from '../data/data';
//styles
import { ConImgCel, ContCardCel, ContCardsCel, ContImgCelda, ParagraphProd } from '../styles/Productos';
//import components
import BannerContactanos from '../components/BannerContactanos';




const Celdas = (props) => {

  //Get data
  const [datos] = data;
  const { productos: { celdas: { cardsCeldas } } } = datos
  // extract values of cards
  const card = Object.values(cardsCeldas);

  //.........................
  // Obtenemos la URL actual utilizando el hook useLocation
  const location = useLocation();

  const [dataUrl, setDataUrl] = useState('');
  const detalles = dataUrl;
  const [dataHelmet, setDataHelmet] = useState();

  //destructuring data
  const { helmet, textP, paragraph, img, imgBack } = detalles;

  useEffect(() => {
    const updateData = async () => {
      const found = card.find(element => element.url === location.pathname);
      setDataUrl(found)
      const dataHelmet = found.metaHelmet;
      setDataHelmet(dataHelmet)
    };
    // Call data update function when URL changes
    updateData();

  }, [location.pathname]);

  if (!dataUrl) {
    // console.log('No data')
    return () => {
      <div>Cargando Data.....</div>
    }
  }

  //....................................
  //destructuring data helmet
  const { meta0, meta1, link} = dataHelmet;
  const dataMeta0 = Object.values(meta0)
  const dataMeta1 = Object.values(meta1)
  console.log(dataMeta0)
  //....................................

  return (
    <>
      <Helmet>
        <title>{helmet} – FACCEL S.A.S.</title>
        {dataMeta0.map((item) => <meta name={item.name} content={item.content} />)}
        {dataMeta1.map((item) => <meta property={item.name} content={item.content} />)}
        <link rel={link.rel} href={link.href} />
      </Helmet>
      <h1>{helmet}</h1>

      <ContCardsCel>
        <ConImgCel>
          <img src={require(`../images/${imgBack}`)} alt={imgBack} />
        </ConImgCel>
        <ContCardCel>
          <ParagraphProd>
            <div>
              <p className='title_card'>{textP}</p>
              <p className='paragraph_card'>{paragraph.split('\n').map((line, i) => {
                return (
                  <li key={i}>
                    {line}
                    <br />
                  </li>
                )
              })}</p>
            </div>
          </ParagraphProd>
          <ContImgCelda>
            <img src={require(`../images/${img}`)} alt={helmet} />
          </ContImgCelda>
          <p className='title_card_2'>{textP}</p>
        </ContCardCel>
      </ContCardsCel>

      <BannerContactanos />
    </>
  )
}
export default Celdas