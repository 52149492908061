import styled from "styled-components";
import ImgBanner from '../images/fondo-servicios.jpg'


export const ConCardsServ = styled.div`
    /* border: 1px solid blue;//.................. */
    background-image: url(${ImgBanner});
    width: 100%;
    height:auto;
    margin-top:clamp(110px, 9vw, 150px);
    padding: 1.5em 0;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ContCard = styled.div`
    /* border: 1px solid red;//............. */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width:650px) {
        flex-direction: column-reverse;
    }
    .title2{
        /* border: 1px solid blue;//................. */
        font-size: clamp(28px, 4vw, 80px);
        font-weight: 800;
        margin-bottom: 1.5rem;
        color: var(--faccel1);
        text-align: center;
        @media (min-width:650px) {
            display: none;
        }
    }
`
export const ContParagraph = styled.div`
    /* border: 1px solid red;//............. */
    width: 43%;
    padding: 2.5vw 4vw;
    @media (max-width:650px) {
        width: 90%;
        .title{
            display: none;
        }
    }
    .title{
        /* border: 1px solid blue;//................. */
        font-size: clamp(28px, 4vw, 80px);
        font-weight: 800;
        margin-bottom: 1.5rem;
        color: var(--faccel1);
    }
    .paragraph{
        /* border: 1px solid blue;//................. */
        font-size: clamp(18px, 2vw, 30px);
        text-align:justify;
    }
`
export const ContImg = styled.div`
    /* border: 1px solid red;//............... */
    width: 50%;
    overflow: hidden;
    @media (max-width:650px) {
        width: 70%;
    }
    @media (max-width:400px) {
        width: 90%;
    }
    img{
        width: 100%;
        object-fit: center;
        object-position: center center;
    }
`



export const CardsServ = styled.div`
    /* border: 1px solid blue;//.................. */
    width: 100%;
    height: auto;
    min-height: 250px;
    display: flex;
    background-color: var(--color2);
    margin-bottom: 2rem;
    @media (max-width:700px) {
        flex-direction: column;
        height: auto;
        width: 90%;
    }
    &:nth-child(even){
        flex-direction: row-reverse;
        @media (max-width:700px) {
            flex-direction: column;
        }
    }
    &:nth-last-child(1){
        background-color: var(--faccel1);
        color: var(--color5);
        .title_card{
            color: var(--color5);
        }
        .BtnLast{
            div{
                background-color: var(--color5);
                color: var(--faccel1);
                border: 2px solid var(--color5);//...........
                &:active{
                    border: 2px solid var(--faccel1);//...........  
                }
            }
        }
    }

`
export const ParagraphServ = styled.div`
    /* border: 1px solid blue;//................. */
    width: 50%;
    padding: 2.5vw 2.2vw;
    height: auto;
    display: flex;
    align-items: center;
    @media (max-width:700px) {
        padding: 1em;
        width: 100%;
        height:50%;
    }
    div{
        /* border: 1px solid blue;//................. */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title_card{
            font-size: clamp(25px, 3.8vw, 70px);
            font-weight: 800;
            margin-bottom: 1rem;
            color: var(--faccel1);
    }
    .p_card{
        font-size: clamp(12px, 1.3vw, 25px);
        display: flex;
    }
    }
    
`
export const ConImgServ = styled.div`
    /* border: 1px solid red;//................. */
    width: 50%;
    overflow: hidden;
    @media (max-width:700px) {
        height:200px;
        width: 100%;
    }
    img{
        /* border: 1px solid red;//................. */
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position:center center;
    }
`