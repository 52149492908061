import React from 'react'
//import data
import { data } from '../data/data';
// styles
import {
    BtnLinkSec,
    BtnLinkSec2,
    CardSec,
    CardsSec,
    CardsSec2,
    ConCardsSec,
    ConImgSec,
    ConImgSec2,
    ParagraphSec
} from '../styles/CardsLanding';



const CardsLanding = () => {

    //Get data
    const [datos] = data;
    //destructuring data
    const { inicio: { whatsappUrl }, servicios, certificaciones } = datos;
    // console.log(servicios.img)

    return (
        <>
            <ConCardsSec>

                <CardsSec key={servicios.id}>
                    <ConImgSec>
                        <img src={require(`../images${servicios.img}`)} alt={servicios.id} />
                    </ConImgSec>
                    <ParagraphSec>
                        <div>
                            <p className='title_card'>{servicios.title}</p>
                            <br />
                            <br />
                            <p className='p_card'>{servicios.paragraph.split('\n').map((line, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                )
                            })}</p>
                            <BtnLinkSec to={servicios.urlS}><div>VER MÁS</div></BtnLinkSec>
                        </div>
                    </ParagraphSec>
                </CardsSec >

                <CardsSec2 key={certificaciones.id}>
                    <ConImgSec2>
                        <img src={require(`../images/${certificaciones.img}`)} alt={certificaciones.id} />
                    </ConImgSec2>
                    <CardSec>
                        <p className='title_card2'>{certificaciones.title}</p>
                        <p className='p_card2'>{certificaciones.paragraph}</p>
                        <BtnLinkSec2 to={whatsappUrl} target='_blank'>
                            <div>SOLICITAR CERTIFICADO VÍA WHATSAPP</div>
                        </BtnLinkSec2>
                        <BtnLinkSec2 to={certificaciones.url}>
                            <div>SOLICITAR CERTIFICADO VÍA MAIL</div>
                        </BtnLinkSec2>
                    </CardSec>
                </CardsSec2 >

            </ConCardsSec>

        </>
    )
}

export default CardsLanding