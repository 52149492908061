import React from 'react'
//import react router dom
import { Link } from 'react-router-dom';
//import data
import { data } from '../data/data';
// icons
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
//styles
import { AccordionFooter, AddressFooter, BlockNav, ConAccordion, ConEmprFooter, ConServFooter, ConsultasFooter, ContFooter, ContUlPoliticas, Copy, EnlaceDesplegable, LinkFooter, LogoFooter, MobileMenu, Redes, TextLink, TitleAccor } from '../styles/Footer';
// Images
import LogFooter from '../images/logo_faccel-blanco.svg';
//Acordion
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const Footer = () => {

    // get data
    const [datos] = data;
    // destructuring data
    const { inicio, certificaciones, contacto,
        servicios: { nameS, urlS, dropDownMenu },
        empresa: { dropDownMenu: { nosotros: { name, url } } },
        productos: { celdas, encerramientos, }
    } = datos;
    const serviciosSub = Object.values(dropDownMenu);
    const celdasSub = Object.values(celdas.cardsCeldas);
    const encerramientosSub = Object.values(encerramientos.cardsEncerramientos);
    // console.log(politicas)




    return (
        <footer className='footer'>

            <ContFooter>

                <LogoFooter>
                    <div>
                        <img src={LogFooter} alt="logoItic" />
                    </div>
                </LogoFooter>

                <BlockNav>

                    <ConEmprFooter>
                        <TextLink to={url}>{name}</TextLink >
                        <br />
                        <TextLink to={urlS}>{nameS}</TextLink >
                        {
                            serviciosSub.map((item) =>
                                <LinkFooter key={item.id} to={item.url}>{item.name}</LinkFooter>
                            )
                        }
                    </ConEmprFooter>

                    <ConServFooter>
                        <TextLink >{celdas.nameC}</TextLink >
                        {
                            celdasSub.map((item) =>
                                <LinkFooter key={item.name} to={item.url}>{item.name}</LinkFooter>
                            )
                        }
                    </ConServFooter>

                    <ConServFooter>
                        <TextLink to={encerramientos.url}>{encerramientos.nameE}</TextLink >
                        {
                            encerramientosSub.map((item) =>
                                <LinkFooter key={item.name} to={item.url}>{item.name}</LinkFooter>
                            )
                        }
                        <br />
                        <TextLink to={certificaciones.url}>{certificaciones.name}</TextLink >
                        {/* <TextLink to={contacto.url}>{contacto.name}</TextLink > */}
                    </ConServFooter>

                    <ConAccordion>
                        <MobileMenu>
                            <TextLink to={url}>{name}</TextLink >
                        </MobileMenu>

                        <AccordionFooter>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <TitleAccor>{nameS}</TitleAccor>
                            </AccordionSummary>
                            <AccordionDetails>
                                <EnlaceDesplegable>
                                    {
                                        serviciosSub.map((item) =>
                                            <ul id="droppin" key={item.id} >
                                                <li key={item.name} id={item.name} >
                                                    <Link to={item.url}>{item.name}</Link>
                                                </li>
                                            </ul>
                                        )
                                    }
                                </EnlaceDesplegable>
                            </AccordionDetails>
                        </AccordionFooter>

                        <AccordionFooter>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <TitleAccor>{celdas.nameC}</TitleAccor>
                            </AccordionSummary>
                            <AccordionDetails>
                                <EnlaceDesplegable>
                                    {
                                        celdasSub.map((item) =>
                                            <ul id="droppin" key={item.id} >
                                                <li key={item.name} id={item.name} >
                                                    <Link to={item.url}>{item.name}</Link>
                                                </li>
                                            </ul>
                                        )
                                    }
                                </EnlaceDesplegable>
                            </AccordionDetails>
                        </AccordionFooter>

                        <AccordionFooter>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <TitleAccor>{encerramientos.nameE}</TitleAccor>
                            </AccordionSummary>
                            <AccordionDetails>
                                <EnlaceDesplegable>
                                    {
                                        encerramientosSub.map((item) =>
                                            <ul id="droppin" key={item.id} >
                                                <li key={item.name} id={item.name} >
                                                    <Link to={item.url}>{item.name}</Link>
                                                </li>
                                            </ul>
                                        )
                                    }
                                </EnlaceDesplegable>
                            </AccordionDetails>
                        </AccordionFooter>

                        <MobileMenu>
                            <TextLink to={certificaciones.url}>{certificaciones.name}</TextLink >
                            {/* <TextLink to={contacto.url}>{contacto.name}</TextLink > */}
                        </MobileMenu>
                    </ConAccordion>

                    <ConsultasFooter>
                        <AddressFooter>
                            <p>{contacto.name}</p>
                            <Link to={contacto.url}><PersonOutlineOutlinedIcon />{contacto.nameFooter}</Link>
                            <span className='address' ><EmailOutlinedIcon /><Link to={`mailto:${inicio.email}`}>{inicio.email}</Link></span>
                            <span className='address' ><PhoneAndroidOutlinedIcon />{inicio.phone}</span>
                            <span className='address' ><LocationOnOutlinedIcon />Planta de Fabricación: <br /> {inicio.address2}</span>
                            <span className='address' ><LocationOnOutlinedIcon />Local Comercial: <br /> {inicio.address}</span>
                            {/* <span className='building'>{inicio.addressBuilding}</span> */}
                        </AddressFooter>
                    </ConsultasFooter>
                </BlockNav>

                <Redes>
                    <h3>SÍGUENOS</h3>
                    <Link target="_blank" to={inicio.facebookUrl}><FacebookIcon /></Link>
                    <Link target="_blank" to={inicio.instagramUrl}><InstagramIcon /></Link>
                    {/* <Link target="_blank" to={inicio.twitterUrl}><TwitterIcon /></Link> */}
                    {/* <Link target="_blank" to={inicio.linkedInUrl}><LinkedInIcon /> </Link> */}
                </Redes>

                <Copy><p>&copy; 2023 FACCEL INGENIERÍA ELECTRICA S.A.S.</p><p>Todos los derechos reservados.</p></Copy>

            </ContFooter>

        </footer>
    )
}

export default Footer